body {
  min-width: 1200px;
  overflow: scroll;
  background-color: #efefef;
}
.main {
  min-width: 100%;
}
.main .logo-con {
  height: 64px;
  padding: 10px;
}
.main .logo-con img {
  height: 44px;
  width: auto;
  display: block;
  margin: 0 auto;
}
.main .header-con {
  background: #fff;
  padding: 0 20px;
  width: 100%;
}
.main .main-body {
  width: 100%;
  height: auto;
  display: flex;
  padding: 10px 20px 20px 20px;
  justify-content: space-between;
}
.main .main-body .main-content-con {
  width: calc(100% - 200px);
  margin-top: 0;
  height: auto;
}
.main .main-body .main-content-con .main-layout-con {
  height: auto;
  margin-bottom: 20px;
  padding: 0;
}
.main .main-body .main-content-con .main-layout-con .content-wrapper {
  width: 100%;
  margin: 0 auto;
  height: auto;
}
@media (min-width: 1400px) {
  .main-body {
    padding: 10px 40px 20px 40px !important;
  }
}
