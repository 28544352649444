body{
  min-width: 1200px;
  overflow: scroll;
  background-color: #efefef;
}

.main{
  min-width: 100%;

  .logo-con{
    height: 64px;
    padding: 10px;
    img{
      height: 44px;
      width: auto;
      display: block;
      margin: 0 auto;
    }
  }
  .header-con{
    background: #fff;
    padding: 0 20px;
    width: 100%;
  }

  .main-body{
    width: 100%;
    height: auto;
    //background: #1cb979;
    display: flex;
    padding: 10px 20px 20px 20px;
    justify-content: space-between;

    .main-content-con{
      width: ~'calc(100% - 200px)';
      margin-top: 0;
      height: auto;

      .main-layout-con{
        height: auto;
        margin-bottom: 20px;
        padding: 0;

        .content-wrapper{
          width: 100%;
          margin: 0 auto;
          height: auto;
        }
      }
    }
  }

}

@media (min-width: 1400px){
  .main-body{
    padding: 10px 40px 20px 40px !important;
  }
}