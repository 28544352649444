@import '~view-design/src/styles/index.less';

//@menu-dark-title: #001529;
//@menu-dark-active-bg: #000c17;
@layout-sider-background: #ffffff;


// Prefix
@css-prefix             : ivu-;
@css-prefix-iconfont    : ivu-icon;

// Color
@primary-color          : #3c4353;
@info-color             : #0c64eb;
@success-color          : #1cb979;
@warning-color          : #ff9900;
@error-color            : #ff3300;
@link-color             : #3c4353;
@link-hover-color       : tint(@link-color, 20%);
@link-active-color      : shade(@link-color, 5%);
@selected-color         : fade(@primary-color, 90%);
@tooltip-color          : #fff;
@subsidiary-color       : #9ea7b4;
@rate-star-color        : #f5a623;

// Base
@body-background        : #efefef;
@font-family            : "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
@code-family            : Consolas,Menlo,Courier,monospace;
@title-color            : #464c5b;
@text-color             : #323c47;
@font-size-base         : 13px;
@font-size-small        : 12px;
@line-height-base       : 1.5;
@line-height-computed   : floor((@font-size-base * @line-height-base));
@border-radius-base     : 0;
@border-radius-small    : 0;
@cursor-disabled        : not-allowed;

// Border color
@border-color-base      : #f2f3f8;  // outside
@border-color-split     : #f2f3f8;  // inside

// Background color
@background-color-base        : #e2e2e2;  // base
@background-color-select-hover: @input-disabled-bg;
@tooltip-bg                   : rgba(70, 76, 91, .9);
@head-bg                      : #f9fafc;
@table-thead-bg               : #f5f7f9;
@table-td-stripe-bg           : #f5f7f9;
@table-td-hover-bg            : #ebf7ff;
@table-td-highlight-bg        : #ebf7ff;
@menu-dark-active-bg          : #313540;
@date-picker-cell-hover-bg    : #e1f0fe;

// Shadow
@shadow-color           : rgba(0, 0, 0, .01);
@shadow-base            : @shadow-down;
@shadow-card            : 0 1px 1px 0 rgba(0,0,0,.1);
@shadow-up              : 0 -1px 6px @shadow-color;
@shadow-down            : 0 1px 6px @shadow-color;
@shadow-left            : -1px 0 6px @shadow-color;
@shadow-right           : 1px 0 6px @shadow-color;

// Button
@btn-font-weight        : normal;
@btn-padding-base       : 6px 15px;
@btn-padding-large      : 6px 15px 7px 15px;
@btn-padding-small      : 2px 7px;
@btn-font-size          : 12px;
@btn-font-size-large    : 14px;
@btn-border-radius      : 3px;
@btn-border-radius-small: 0;
@btn-group-border       : shade(@primary-color, 0%);

@btn-disable-color      : #c3cbd6;
@btn-disable-bg         : @background-color-base;
@btn-disable-border     : @border-color-base;

@btn-default-color      : @text-color;
@btn-default-bg         : @background-color-base;
@btn-default-border     : @border-color-base;

@btn-primary-color      : #fff;
@btn-primary-bg         : @primary-color;

@btn-ghost-color        : @text-color;
@btn-ghost-bg           : transparent;
@btn-ghost-border       : @border-color-base;

@btn-circle-size        : 32px;
@btn-circle-size-large  : 36px;
@btn-circle-size-small  : 24px;

// Layout and Grid
@grid-columns           : 24;
@grid-gutter-width      : 0;

// Legend
@legend-color           : #999;

// Input
@input-height-base           : 32px;
@input-height-large          : 36px;
@input-height-small          : 24px;

@input-padding-horizontal    : 7px;
@input-padding-vertical-base : 4px;
@input-padding-vertical-small: 1px;
@input-padding-vertical-large: 6px;

@input-placeholder-color     : @btn-disable-color;
@input-color                 : @text-color;
@input-border-color          : @border-color-base;
@input-bg                    : #fff;

@input-hover-border-color    : @primary-color;
@input-focus-border-color    : @primary-color;
@input-disabled-bg           : #f3f3f3;

// Tag
@tag-font-size          : 12px;

// Media queries breakpoints
// Extra small screen / phone
@screen-xs              : 480px;
@screen-xs-min          : @screen-xs;
@screen-xs-max          : (@screen-xs-min - 1);

// Small screen / tablet
@screen-sm              : 768px;
@screen-sm-min          : @screen-sm;
@screen-sm-max          : (@screen-sm-min - 1);

// Medium screen / desktop
@screen-md              : 992px;
@screen-md-min          : @screen-md;
@screen-md-max          : (@screen-md-min - 1);

// Large screen / wide desktop
@screen-lg              : 1200px;
@screen-lg-min          : @screen-lg;
@screen-lg-max          : (@screen-lg-min - 1);

// Z-index
@zindex-spin            : 8;
@zindex-affix           : 10;
@zindex-back-top        : 10;
@zindex-select          : 900;
@zindex-modal           : 1000;
@zindex-message         : 1010;
@zindex-notification    : 1010;
@zindex-tooltip         : 1060;
@zindex-loading-bar     : 2000;

// Animation
@animation-time         : .3s;
@transition-time        : .2s;
@ease-in-out            : ease-in-out;

// Slider
@slider-color              : tint(@primary-color, 20%);
@slider-height             : 4px;
@slider-margin             : 16px 0;
@slider-button-wrap-size   : 18px;
@slider-button-wrap-offset : -4px;
@slider-disabled-color     : #ccc;

img[src=""],img:not([src]){
  opacity:0;
}

.ivu-drawer-content{
  background: #f4f5fa;
}

.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: left;
  background: #fff;
}

.shadows{
  box-shadow: 0 1px 1px rgba(0,0,0,.05), 0 2px 6px 0 rgba(0,0,0,.045);
}

*, :after, :before{
  box-sizing: border-box;
}

.ivu-timeline-item {
  padding: 0 0 5px 0 !important;
}

.ivu-select-dropdown{
  -webkit-box-shadow: ~'0 1px 6px rgb(0 0 0 / 20%)';
  box-shadow: ~'0 1px 6px rgb(0 0 0 / 20%)';
}

.ivu-dropdown-item{
  text-align: center;
}

.ivu-select{
  border: 1px solid #f2f3f8;
  border-radius: 2px;
  cursor: pointer;
}

.ivu-radio-inner:after{
  border-radius: 50%;
}

.ivu-radio-border{
  height: auto;
  padding: 2px 14px;
}

.ivu-tree-title-selected, .ivu-tree-title-selected:hover{
  background: #515a6e;
  color: #fff !important;
  border-radius: 2px;
}
.ivu-input[disabled], fieldset[disabled] .ivu-input{
  color: #323c46;
}

.ivu-radio-wrapper{
  margin-top: 0px;
}


.ivu-tree-title{width:90% !important; }
.itree .ivu-tree-title{width:100% !important;}

// @media (min-width: 1400px){
//   .cd-page-fixed{right:40px !important;}
// }

